#terms-container {
  padding-bottom: 2rem;
}
#terms-container > * {
  padding-left: 2rem;
  padding-right: 2rem;
}

#terms-container span, li {
  font-weight: 400;
}
#terms-container ol {
  list-style:decimal;
  margin-left: 1rem;
}

#terms-container ol > ol {
  margin-left: 1.5rem;
  list-style: lower-alpha;
  list-style-position: outside;
}